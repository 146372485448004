import React from 'react';
import classes from "../Total/Total.module.scss";
import Error from "../../../Error/Error";
import InputMask from "react-input-mask";

const InheritanceInput = ( props ) => {

    const mask = props.type === 'year' ? '9999999' : '$9999999'

    return (
        <div className={classes.TotalWrap}>
            {
                props.error &&
                <Error
                    message={props.errorMessage}
                />
            }
            <div className={classes.InputWrap}>
                <InputMask
                    mask={mask}
                    value={`$${props.value}`}
                    onChange={props.onChange}
                    maskPlaceholder={null}
                />
            </div>
            <p>{props.title}</p>
        </div>
    )
};

export default InheritanceInput;