import React from 'react';
import { setQppSpouse } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import InputStep from "../InputStep";

const QppAmountSpouse = ( props ) => {

    const onChange = value => {
        props.setQppAmountSpouse(value)
    };

    return (
        <InputStep
            label={'Monthly QPP'}
            onChange={onChange}
            max={3000}
            total={props.total}
        />
    )
};

function mapStateToProps(state) {
    return {
        total: state.sourceOfIncome.spouse.qpp.val
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setQppAmountSpouse: (val) => dispatch(setQppSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QppAmountSpouse);