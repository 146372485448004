import React, { useCallback, useEffect, useState } from 'react';
import InheritanceInput from "../Step3/Inheritance/InheritanceInput";
import PropTypes from "prop-types";

const InputStep = ( props ) => {

    const [value, setValue] = useState(props.total || 0);
    const [error, setError] = useState(false);
    const initErrorMessage = 'Value can not be less than $1'
    const [errorMessage, setErrorMessage] = useState(initErrorMessage);

    const validation = useCallback((val) => {
        if(val < 1){
            setError(true);
            setErrorMessage(initErrorMessage)
        }else if(val >props.max){
            setError(true);
            setErrorMessage(`Value can not be greater than ${props.max}`)
        }else {
            setError(false)
        }
    }, [props.max])

    const onChange = e => {
        const value = +e.target.value.replace(/\$|\s/g, '');
        setValue(value)
        validation(value)
        props.onChange(value)
    };

    useEffect(()=> {
        validation(props.total)
    }, [props, validation]);

    return (
        <InheritanceInput
            title={props.label}
            onChange={onChange}
            error={error}
            value={value}
            errorMessage={errorMessage}
        />
    )
};

InputStep.propTypes ={
    total: PropTypes.number,
    max: PropTypes.number,
    label: PropTypes.string,
};

export default InputStep;
