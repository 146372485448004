import { fetchRadioError } from "./aboutYou";
import SourceOfIncomeInitState from "../initStates/SourceOfIncomeInitState";
import { SET_OTHER_SOURCES_SELECTOR_YOU } from "./actionTypes";
import { SET_OTHER_SOURCES_SELECTOR_SPOUSE } from "./actionTypes";
import { SET_CPP_YOU } from "./actionTypes";
import { SET_QPP_YOU } from "./actionTypes";
import { SET_OAS_PENSION_YOU } from "./actionTypes";
import { SET_EMPLOYER_PENSION_YOU } from "./actionTypes";
import { SET_OTHER_YOU } from "./actionTypes";
import { SET_CPP_SPOUSE } from "./actionTypes";
import { SET_QPP_SPOUSE } from "./actionTypes";
import { SET_OAS_PENSION_SPOUSE } from "./actionTypes";
import { SET_EMPLOYER_PENSION_SPOUSE } from "./actionTypes";
import { SET_OTHER_SPOUSE } from "./actionTypes";
import { SET_FOURTH_STEP_COMPLETED } from "./actionTypes";

let localStorageState = SourceOfIncomeInitState;

function setLocalStorageData(params) {

    const sourceOfIncome = localStorage.getItem('sourceOfIncome');

    localStorageState = sourceOfIncome ? JSON.parse(sourceOfIncome) : localStorageState;

    switch (params.type ) {
        case 'setCppYou':
            localStorageState.you.cpp.val = params.val;
            break;
        case 'setQppYou':
            localStorageState.you.qpp.val = params.val;
            break;
        case 'setOasPensionYou':
            localStorageState.you.oasPension.val = params.val;
            break;
        case 'setEmployerPensionYou':
            localStorageState.you.employerPension.val = params.val;
            break;
        case 'setOtherYou':
            localStorageState.you.other.val = params.val;
            break;
        case 'setCppSpouse':
            localStorageState.spouse.cpp.val = params.val;
            break;
        case 'setQppSpouse':
            localStorageState.spouse.qpp.val = params.val;
            break;
        case 'setOasPensionSpouse':
            localStorageState.spouse.oasPension.val = params.val;
            break;
        case 'setEmployerPensionSpouse':
            localStorageState.spouse.employerPension.val = params.val;
            break;
        case 'setOtherSpouse':
            localStorageState.spouse.other.val = params.val;
            break;
        case 'fourthStepCompleted':
            localStorageState.fourthStepCompleted = params.val
            break;
        default:
            return true
    }

    localStorage.setItem('sourceOfIncome', JSON.stringify(localStorageState));
}

export function updateSourceOfIncomeYouData(checkedItems) {
    return async dispatch => {
        try {
            const sourceOfIncome = localStorage.getItem('sourceOfIncome')

            const data = {}
            if(sourceOfIncome) {
                localStorageState = JSON.parse(sourceOfIncome)

                checkedItems.forEach((checked, name)=>{
                    if (name === 'cppYou') {
                        data.cppYou = checked
                        localStorageState.you.cpp.exist = checked
                        if(!checked) {
                            localStorageState.you.cpp.val = 0
                        }
                    }
                    if (name === 'qppYou') {
                        data.qppYou = checked
                        localStorageState.you.qpp.exist = checked
                        if(!checked) {
                            localStorageState.you.qpp.val = 0
                        }
                    }
                    if (name === 'oasPensionYou') {
                        data.oasPensionYou = checked
                        localStorageState.you.oasPension.exist = checked
                        if(!checked) {
                            localStorageState.you.oasPension.val = 0
                        }
                    }
                    if (name === 'employerPensionYou') {
                        data.employerPensionYou = checked
                        localStorageState.you.employerPension.exist = checked
                        if(!checked) {
                            localStorageState.you.employerPension.val = 0
                        }
                    }
                    if (name === 'otherYou') {
                        data.otherYou = checked
                        localStorageState.you.other.exist = checked
                        if(!checked) {
                            localStorageState.you.other.val = 0
                        }
                    }
                })

            }
            localStorage.setItem('sourceOfIncome', JSON.stringify(localStorageState));

            dispatch(updateSourceOfIncomeSelectorYou(data))
        } catch (e) {
            dispatch(fetchRadioError(e))
        }
    }
}

export function updateSourceOfIncomeSelectorYou(data) {
    return {
        type: SET_OTHER_SOURCES_SELECTOR_YOU,
        data
    }
}

export function updateSourceOfIncomeSpouseData(checkedItems) {
    return async dispatch => {
        try {
            const sourceOfIncome = localStorage.getItem('sourceOfIncome')

            const data = {}
            if(sourceOfIncome) {
                localStorageState = JSON.parse(sourceOfIncome)

                checkedItems.forEach((checked, name)=>{
                    if (name === 'cppSpouse') {
                        data.cppSpouse = checked
                        localStorageState.spouse.cpp.exist = checked
                        if(!checked) {
                            localStorageState.spouse.cpp.val = 0
                        }
                    }
                    if (name === 'qppSpouse') {
                        data.qppSpouse = checked
                        localStorageState.spouse.qpp.exist = checked
                        if(!checked) {
                            localStorageState.spouse.qpp.val = 0
                        }
                    }
                    if (name === 'oasPensionSpouse') {
                        data.oasPensionSpouse = checked
                        localStorageState.spouse.oasPension.exist = checked
                        if(!checked) {
                            localStorageState.spouse.oasPension.val = 0
                        }
                    }
                    if (name === 'employerPensionSpouse') {
                        data.employerPensionSpouse = checked
                        localStorageState.spouse.employerPension.exist = checked
                        if(!checked) {
                            localStorageState.spouse.employerPension.val = 0
                        }
                    }
                    if (name === 'otherSpouse') {
                        data.otherSpouse = checked
                        localStorageState.spouse.other.exist = checked
                        if(!checked) {
                            localStorageState.spouse.other.val = 0
                        }
                    }
                })

            }
            localStorage.setItem('sourceOfIncome', JSON.stringify(localStorageState));

            dispatch(updateSourceOfIncomeSelectorSpouse(data))
        } catch (e) {
            dispatch(fetchRadioError(e))
        }
    }

}

export function updateSourceOfIncomeSelectorSpouse(data) {
    return {
        type: SET_OTHER_SOURCES_SELECTOR_SPOUSE,
        data
    }
}

export function setCppYou(val) {
    setLocalStorageData({
                            type: 'setCppYou',
                            val
                        })
    return {
        type: SET_CPP_YOU,
        val
    }
}

export function setQppYou(val) {
    setLocalStorageData({
                            type: 'setQppYou',
                            val
                        })
    return {
        type: SET_QPP_YOU,
        val
    }
}

export function setOasPensionYou(val) {
    setLocalStorageData({
                            type: 'setOasPensionYou',
                            val
                        })
    return {
        type: SET_OAS_PENSION_YOU,
        val
    }
}

export function setEmployerPensionYou(val) {
    setLocalStorageData({
                            type: 'setEmployerPensionYou',
                            val
                        })
    return {
        type: SET_EMPLOYER_PENSION_YOU,
        val
    }
}

export function setOtherYou(val) {
    setLocalStorageData({
                            type: 'setOtherYou',
                            val
                        })
    return {
        type: SET_OTHER_YOU,
        val
    }
}

export function setCppSpouse(val) {
    setLocalStorageData({
                            type: 'setCppSpouse',
                            val
                        })
    return {
        type: SET_CPP_SPOUSE,
        val
    }
}

export function setQppSpouse(val) {
    setLocalStorageData({
                            type: 'setQppSpouse',
                            val
                        })
    return {
        type: SET_QPP_SPOUSE,
        val
    }
}

export function setOasPensionSpouse(val) {
    setLocalStorageData({
                            type: 'setOasPensionSpouse',
                            val
                        })
    return {
        type: SET_OAS_PENSION_SPOUSE,
        val
    }
}

export function setEmployerPensionSpouse(val) {
    setLocalStorageData({
                            type: 'setEmployerPensionSpouse',
                            val
                        })
    return {
        type: SET_EMPLOYER_PENSION_SPOUSE,
        val
    }
}

export function setOtherSpouse(val) {
    setLocalStorageData({
                            type: 'setOtherSpouse',
                            val
                        })
    return {
        type: SET_OTHER_SPOUSE,
        val
    }
}


export function fourthStepCompleted(val) {
    setLocalStorageData({
                            type: 'fourthStepCompleted',
                            val,
                        })

    return {
        type: SET_FOURTH_STEP_COMPLETED,
        val,
    }
}