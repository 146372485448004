import React from 'react';
import { setQppYou } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import InputStep from "../InputStep";

const QppAmountYou = ( props ) => {

    const onChange = value => {
        props.setQppAmountYou(value)
    };

    return (
        <InputStep
            label={'Monthly QPP'}
            onChange={onChange}
            max={3000}
            total={props.qppTotal}
        />
    )
};

function mapStateToProps(state) {
    return {
        qppTotal: state.sourceOfIncome.you.qpp.val
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setQppAmountYou: (val) => dispatch(setQppYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QppAmountYou);