import React, { useEffect, useCallback, useState } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import QppAmountYou from "../../../components/ContentRow/Step4/QppAmount/QppAmountYou";
import QppAmountSpouse from "../../../components/ContentRow/Step4/QppAmount/QppAmountSpouse";
import { fourthStepCompleted } from "../../../store/actions/SourceOfIncomeActions";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const Qpp = ( props ) => {
    const titles = {
        layoutTitle: 'QPP',
        sectionTitle: 'The maximum QPP benefit is $1,177.30 per month. How much will you receive?',
    };

    const title = "Monthly QPP in retirement";

    const [next, setNext] = useState(retirementCalcLinks.sourceOfIncomeSummary);

    const nextLink = useCallback(() => {
        if(props.sourceOfIncomeYou.oasPension.exist || props.sourceOfIncomeSpouse.oasPension.exist) {
            return retirementCalcLinks.oasPension;
        }else if(props.sourceOfIncomeYou.employerPension.exist || props.sourceOfIncomeSpouse.employerPension.exist){
            return retirementCalcLinks.employerPensionAmount;
        }else if(props.sourceOfIncomeYou.other.exist || props.sourceOfIncomeSpouse.other.exist){
            return retirementCalcLinks.otherAmount;
        }else {
            return retirementCalcLinks.sourceOfIncomeSummary
        }
    }, [props]);

    useEffect(() => {
        props.fourthStepCompleted(false)
        setNext(nextLink())
    }, [props,nextLink])

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep4={(2/6)*100}
            active={'step4'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='qpp'
                sectionTitle={titles.sectionTitle}
            >

                {
                    props.sourceOfIncomeYou.qpp.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <QppAmountYou/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.sourceOfIncomeSpouse.qpp.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <QppAmountSpouse/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        sourceOfIncomeYou: state.sourceOfIncome.you,
        sourceOfIncomeSpouse: state.sourceOfIncome.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fourthStepCompleted: (val) => dispatch(fourthStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Qpp);